//tools import
import React, { Component } from 'react'
import { Link } from 'react-router-dom';

//css Import
import './landingPage.css';

//Animation Import
import Anime from 'react-anime';

//Image Imports
import silentCareLogo from '../../Images/logo.png';

// React-icons//
import {BsArrowRightShort} from "react-icons/bs";


export default class landingPage extends Component {
    render() {
        return (
            <div className="landingContainer">
                <div className="logoContainer">
                    <Anime className = "animeDiv" opacity = {[0,1]} duration={18000}>
                        <img src={silentCareLogo} className="companyLogo" alt="logo" />
                    </Anime>
                    <Anime className="animeHmeBtnDiv" opacity = {[0,1]} duration={20000} delay={2000}>
                        <Link to="/HomePage" className="landingHomeBtn">home <BsArrowRightShort className="homeArrow" /> </Link>
                    </Anime>
                </div>
            </div>
        )
    }
}
