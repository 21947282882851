import React, { Component } from 'react'

import '../infoCards/infoCards.css';


export default class InfoCards extends Component {
    render() {
        return (
        <div className="infoCardsContainer">
            <div className="card card1">
                <div className="cardImg cardImg1"></div>
                <div className="cardText">
                    <h2 className="cardTitle">Our Approach</h2>
                    <p className="cardDesc"> Everything we do is driven by our universal mission: to enhance the lives of ageing adults and their families. The solution for families that need an extra level of care. The place we call home is the place that feels most comfortable and secure

                    A sense of home can become especially important as we age. Our in-home senior care Services help ease the challenge of ageing while encouraging seniors to enjoy the comforts of home. </p>
                </div>
            </div>
            {/* Card Two     */}
            <div className="card card1">
                <div className="cardImg cardImg2"></div>
                <div className="cardText">
                    <h2 className="cardTitle">What We Offer</h2>
                    <p className="cardDesc"> Providing innovative solutions that improve health and quality of life for those in need is what we strive for, here at Silent Care we have established ourselves as one of the most experienced homecare companies in the industry.

                    Our approach to quality care focuses on a commitment to providing a level of expertise, training, patient service, and monitoring that is difficult to match in our industry. </p>
                </div>
            </div> 
            {/* Card Three */}
            <div className="card card1">
                <div className="cardImg cardImg3"></div>
                <div className="cardText">
                    <h2 className="cardTitle">How We Work</h2>
                    <p className="cardDesc"> Elderly care, is the fulfillment of the special needs and requirements that are unique to senior This broad term encompasses such services as assisted living, long term care, hospice care, and home care.

                    due to the wide variety of elderly care found nationally,as well as differentiating regional perspectives on elderly citizens,it cannot be limited to any single practice. </p>
                </div>
            </div> 
        </div>
        )
    }
}
