import React from 'react'

//CSS import
import '../showCaseImgs/showCase.css';

export default function HealthyChildShowCase() {
    return (
        <div>
            <div className ="healthyChildShowCase">
                <div className="healthyChildrenText">
                    <h1 className="healthyChildrenTitle">healthy children and youth program</h1>
                    <p className="healthyChildrenDesc">The HCY Program provides service coordination and authorization for Medically Necessary Services for MO HealthNet recipients with Special Health Care needs from birth to age 21.

                    Caring for all types of children is what we are here for, every employee here at Silent Care is specially trained to care for children with special needs.</p>
                </div>
            </div>
        </div>
    )
}

