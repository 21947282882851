import React from 'react';
import { Route, BrowserRouter, Switch, withRouter} from 'react-router-dom';



//COMPONENTS IMPORTS 
import LandingPage from './components/landingPage/LandingPage';
import HomePage from './components/homePage/HomePage';
import toTop from './components/toTopBtn/toTop';




function App() {
  return (
    <div className="appContainer">
      <BrowserRouter>
        <Switch>
          <Route exact path= "/" component = {LandingPage} />
          <Route exact path = "/HomePage" component ={HomePage} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default withRouter (App);
