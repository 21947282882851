import React from 'react'

import '../footer/footer.css';

export default function Footer() {
    return (
        <div className="footerContainer">
            <p className="footerCopyRight footerText">copyright &copy; silent care LLC</p>
            {/* <p className="footerEmail footerText"><strong>silentcare11@gmail.com</strong></p> */}
        </div>
    )
}
