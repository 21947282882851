import React from 'react'

//CSS import
import '../showCaseImgs/showCase.css';

export default function AslShowcase() {
    return (
        <div>
            <div className ="AslShowCase">
                <div className="aslText">
                    <h1 className="aslTitle">asl care services</h1>
                    <p className="aslDesc">Our ASL service are the providing of counseling, therapy, and other psychiatric services to people who are deaf and hard of hearing in ways that are culturally aware and linguistically accessible.
                    
                    Age-related hearing loss gradually occurs in many people as they get older, typically affecting those over the age of 65.
                    </p>
                </div>
            </div>
        </div>
    )
}
