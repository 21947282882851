import React from 'react'

//Icon Import
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarker} from "react-icons/fa";

//Showcase logo import
import transLogo from '../../Images/transparentLogo.png';
//Animation Import
import Anime from 'react-anime';

//CSS Import
import '../showCaseImgs/showCase.css';

export default function mainShowCase() {
    return (
        <div className="mainShowCaseRow">
             <Anime opacity= {[0,1]} duration={[6000]} scale={[1.3,1]} easing={['easeInOutSine']} loop= {[true]}  className="showCaseImg">
                    <Anime opacity = {[0, 1]} duration = {[6700]} delay= {[6000]}>
                        <div>
                            <div className ="showCaseTitle">
                                <h1 className="introTitle">
                                    let our hands work for you 
                                </h1>
                                <p className="showCaseMessage messageOne">Share memories, not task lists. Hire the best quality of care for your loved ones.
                                </p>
                            </div>
                        </div>
                    </Anime>
                </Anime>
        </div>
    )
}
