import React from 'react';

import * as emailjs from 'emailjs-com';


//Icon Import
import { FaEnvelope } from "react-icons/fa";
import { FaPhoneAlt } from "react-icons/fa";
import { FaMapMarker} from "react-icons/fa";


//CSS Import
import '../contactForm/contactForm.css';

export default function contactForm() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_8wyyia8', 'template_1tm33nt', e.target,  'user_YRfxoksg8AkDeAk6xkUwS')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  }

  return (
      <div className="formContainer">
          <div className="welcomeContactContainer">
            <h1 className="contactWelcomeText">
                contact us!
            </h1>
          </div>
        <div className="contactContainer">
            <div className="contactList">
                <h2 className="contactOption phoneContact"> <FaPhoneAlt /> (314) 395-9361
                </h2>
                <h2 className="contactOption emailContact"> <FaEnvelope /> silentcare11@gmail.com
                </h2>
                <h2 className="contactOption addressContact"> <FaMapMarker /> 1931 Washington St. Florissant, Mo 63033
                </h2>
            </div>
            <form className="contactFormContainer" onSubmit={sendEmail}>
                <input className="formInput name" type="text" placeholder="Full Name" name="user_name" />
                <input className="formInput email" type="email" placeholder="Email" name="user_email" />
                <input className="formInput contactNumber" type="number"  placeholder="Contact Number" name="contact_number" />
                <textarea className="formInput message" placeholder="Please include the service name you are inquiring about." name="message" />
                <input className="submitFormBtn" type="submit" value="Send" />
            </form>
        </div>
    </div>
  );
}
