import React, { Component } from 'react'

//Bootstrap import 
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
//css Import
import '../homePage/homePage.css';

//Animation Import
import Anime from 'react-anime';

//Component Import
//Welcome showcase
import MainShowCase from '../showCaseImgs/MainShowCase';
//About Us
import AboutUs from '../AboutUs/AboutUs';
//Info Cards
import InfoCards from '../infoCards/InfoCards';
//Healthy children showcase
import HealthyChildShowCase from '../showCaseImgs/HealthyChildShowCase';
//Services 
import Services from '../services/Services';
//Asl Showcase
import AslShowcase from '../showCaseImgs/AslShowcase';
//Contact Form
import ContactForm from '../contactForm/ContactForm';
//Footer
import Footer from '../footer/Footer';

export default class HomePage extends Component {
    render() {
        return (
            <div className="gridDisplay">
                <div>
                    <div>
                        <MainShowCase />
                    </div>
                    <div className="aboutUsComponent">
                        < AboutUs />
                    </div>
                        <InfoCards />
                    <div className="healthyChildrenImage">
                        <HealthyChildShowCase />
                    </div>
                    <div className="serviceContainer">
                        <Services />
                    </div>
                    <div className="AslShowcase">
                        <AslShowcase />
                    </div>
                    <div className="contactForm">
                        <ContactForm />
                    </div>
                    <div className="footer">
                        <Footer />
                    </div>
                </div>
            </div>
        )
    }
}