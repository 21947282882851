import React, { Component } from 'react';

//Css Import
import '../AboutUs/AboutUs.css';

export default class aboutUs extends Component {
    render() {
        return (
            <div className="aboutUsContainer">
                <div className="aboutUsWrapper">
                    <h2 className = "aboutUsTitle"><strong>
                        "Caring For Seniors Is A Labor Of Love And Requires A Special Person"
                        </strong>
                    </h2>
                    <p className="aboutUs">
                    Neither giving nor receiving care is easy. Silent Care puts relationships first to support those giving care, to protect the dignity of those receiving it, and to enhance the quality of life for both seniors and their families.
                    </p>
                    <p className= "aboutUs2">
                    Silent Care offers affordable in-home care 365 days a year for those who need it.
                    </p>
                </div> 
            </div>
        )
    }
}
