import React, { useState } from "react";

import '../services/Service.css';

import { BsXSquare } from "react-icons/bs";


import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ModalDialog } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";

let fileList = [
  {
    id: 1,
    service: 'InHome Care',
    description: 'Whether your loved one needs care in their home, a nearby assisted or independent living community or elsewhere, our custom homecare services are provided in many settings.' +
      ' We provide medical treatment needs by our professional caregivers who provide daily assistance to ensure the activities of daily living (ADLs) are met. We ensure medical care is often and more accurately performed by our caregivers. For terminally ill patients, home care may include hospice care. For patients recovering from surgery or illness, home care may include rehabilitative therapies. ' + 'Home health services help adults, seniors, and pediatric clients who are recovering after a hospital or facility stay, or need additional support to remain safely at home and avoid unnecessary hospitalization. ' +

      'These Medicaid-certified services may include short-term nursing, rehabilitative, therapeutic, and assistive home health care. '
  },
  {
    id: 2,
    service: 'Consumer Direct',
    description: 'Consumer Directed Services is our Medicaid based program that provides services to individuals with disabilities, enabling them to live independently. ' +
      'Participants in our CDS program are trained to hire attendants to assist them with task of daily living. The task may incude bathing/showering, toileting, obtaining medication, meal preparation and cleanup, housekeeping/laundry and essential transportation. '
      +
      'The CDS program is designed to assist people with disabilities with receiving the attendant services needed to safely live in a home setting of their choice instead of living in a more restrictive institutional environment. ' + 'SilentCares CDS program is consumer controlled. A main component of consumer control is that the person who receives CDS is the employer of the attendant. CDS program participants are responsible for locating, hiring, setting work schedules and managing attendants. ' +

      'Aqualified friend of family member may be hired as an attendant with the exception of a spouse or anyone under the age of 18. '
  },

  {
    id: 3,
    service: 'Private Duty',
    description: 'We offer nurses who provide private duty care work one-on-one with individual clients. Such care may be provided in the clients home or in an institution, such as a hospital, nursing home or other such facility. ' +

      'Private duty may be paid by private pay, private insurance, managed care organizations, or Medicaid. Many private duty nursing cases involve pediatric patients on Supplemental Security Income (SSI) who have long term illnesses ' +

      'such as Cerebral Palsy (CP) and Traumatic Brain Injuries (TBI). Many patients need care for a Gastrostomy Tube (G-Tube), Tracheostomy (Trach) or Ventilator (Vent). Private duty nurses are usually either Registered Nurses (RNs) or Licensed Practical/Vocational Nurses (LPN/LVNs). Medicare does not pay for private duty nurses. ' +

      'Non-medical care can be provided by the nurse but is most often provided by our unlicensed assistive personnel such as nursing assistants. '
  },

  {
    id: 4,
    service: 'Home-make Chore',
    description: 'Our Homemaker Chore Program helps adults and seniors with disabilities maintain a safe, clean living environment so that they may continue to live independently in the community and avoid institutional placement. ' +

      'Our homemakers help with everyday things such as cleaning, cooking, home maintenance, shopping, laundry and bill payment. These tasks may be performed by any of the Silent Care professionals, or by other persons hired to perform these tasks. ' +

      'Disposing of rubbish, cleaning dirty surfaces, dusting and vacuuming. It may also involve some outdoor chores, such as removing leaves from rain gutters, washing windows and sweeping doormats are a just a few of the types of cleaning activities our caregivers provide. Housecleaning is done to make the home look and smell better and be safer and easier to live in. Without housecleaning lime scale can build up on taps, mold grows in wet areas, smudges on glass surfaces, dust forms on surfaces ' +

      'Tools used in housecleaning include vacuums, brooms, mops and sponges, together with cleaning products such as detergents, disinfectants and bleach.'
  },
  {
    id: 5,
    service: "Nursing Care",
    description:
      'Here at Silent Care our nurses provide nursing services for persons who are home bound and physically unable to visit a physician’s office or other treatment facility. ' +

      'This includes elderly adults with long-term illness or disability, and also persons who are recuperating from major surgery. The duties of our home health nurses range from taking vital signs (blood pressure, pulse and temperature) to assessing health progress and needs. ' +

      'Silent Care nurses change dressings on wounds, and observe how well patients can perform certain tasks that may be hampered by a condition or by having surgery. Our nurses have completed an approved program of study that prepares one to take the licensing examination in the state of Missouri. ' +

      'Our nurses have a strong focus on areas such as medical-surgical nursing, pediatrics, and psychiatry. '
  },
  {
    id: 6,
    service: "Respite Care",
    description:
      'Respite programs provide planned short-term and time-limited breaks for families and other unpaid care givers ' +

      'of children with a developmental delay, children with behavioral problems, adults with an intellectual disability, and adults with cognitive loss in order to support and maintain the primary care giving relationship. ' +

      'Even though many families take great joy in providing care to their loved ones so that they can remain at home, the physical, emotional and financial consequences for the family caregiver can be overwhelming without some support. Respite provides a break for the family caregiver, which may prove beneficial to the health of the caregiver. ' +

      'Here at Silent Care we like to use the term Respite Care, but you may also know it as "short break". '
  },
  {
    id: 7,
    service: "ASL Care",
    description:
      'Our ASL service are the providing of counseling, therapy, and other psychiatric services to people who are deaf and hard of hearing in ways that are culturally aware and linguistically accessible. ' +

      'Age-related hearing loss gradually occurs in many people as they get older, typically affecting those over the age of 65.[15] This type of hearing loss can lead to feelings of embarrassment and isolation ' +

      'due to the fact that those affected may no longer be able to hear family, friends, or simple everyday sounds.  Those with hearing loss are less likely to want to engage in social activities due to frustration over not being able to hear. ' +

      'This is why we are here to help bridge the communication gap between our deaf or hard of hearing clients, with the everyday challenges they face. '
  },

  {
    id: 8,
    service: "Advance Care",
    description:
      'At times, clients may remain at home, yet require specialized assistance due to procedures and equipment that can alter body functions. White & Associates offers the personnel and experience needed to handle these situations with dignity and professionalism.'
      +
      'We offer a wide range of service for any client who may need advanced personal care. Such assistance may include' +

      'Routine personal care of persons with ostomies (including tracheostomies, gastrostomies, colostomies all with well-healed stoma) which includes changing bags, and soap and water hygiene around ostomy site Personal care of persons with catheters, which includes changing bags, and soap and water hygiene around site, Administration of prescribed bowel programs, Application of aseptic dressings, ' +

      'also assistance with noninjectable medications as set up by a licensed nurse. Contact us if you have any questions regarding our Advanced Personal Care Service. '
  },

  {
    id: 9,
    service: "Healthy Children",
    description:
      'The HCY Program provides service coordination & authorization for Medically Necessary Services for MO HealthNet recipients with Special Health Care needs from birth to age 21. ' +

      'Caring for all types of children is what we are here for, every employee here at Silent Care is specially trained to care for children with special needs. ' +

      'Just a few of our activites are sports, community service, outdoor education, and building their social skills. Providing these types of learning activites ensures developmental growth in all aspecs of your childs life. Our Healthy Children Program is a nutritional lesson for the children that attend our program. ' +

      'We show the kids how to choose a healthy meal plan, and what foods are good for them and which ones are not. Or if they already have a nutritional plan, we can help them stay on theirs. Candy and sweets are good to have every once in a while. We tend to find ourselves as parents giving our children candy and sweets without keeping track of how much, with your busy schedule. Allow a Silent Care professional take some of the weight off your shoulders. '
  }
];

function Services() {
  const [showModal, setShowModal] = useState();
  const [service, setService] = useState();

  const handleClose = () => setShowModal(false);
  const handleCancel = () => {
    setShowModal(false);
    setService(null);
  };
  const handleDelete = () => {
    setShowModal(false);
    //delete
    alert(`${service} has been deleted`);
    setService(null);
  };
  return (
    <div>
      <div className="gridView">
      {fileList.map((file) => (
        <div key={file.id}>
          <button className="service"
            onClick={() => {
              setService(file.service);
              setShowModal(true);
            }}
            key={file.id}
          >
            {file.service}
          </button>
        </div>
      ))}
      </div>
      <div>
        <div className="modalBackground">
          <Modal id="modalStyle" show={showModal} onHide={handleClose}>
            <div closeButton>
              {fileList
                .filter((file) => file.service === service)
                .map((fileFiltered) => (
                  <Modal.Header id="serviceModalTitle" key={fileFiltered.id}>{fileFiltered.service}
                    <Button id="closeBtn" onClick={handleCancel}>
                      <BsXSquare />
                    </Button>
                  </Modal.Header>
                ))}
              {fileList
                .filter((file) => file.service === service)
                .map((fileFiltered) => (<Modal.Body id="modalBodyText" key={fileFiltered.id}>{fileFiltered.description}</Modal.Body>
              ))}
              <div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <div className="servicesContainer">
      <Services />
    </div>
  );
}
